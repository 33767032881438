.orders-wrapper {
  height: 100%;
  width: 100%;
  padding: 1.5rem;
  position: relative;
}

.orders-container {
  /* background-color: red; */
  height: auto;
  width: 100%;
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  padding: 1rem;
}

.order-wrapper {
  background-color: antiquewhite;
  height: 100%;
  border-radius: 10px;
  padding: 1rem;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: space-between; */
}

.order-wrapper .order-wrapper-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #444;
}

.order-wrapper .order-wrapper-title p {
  font-weight: bolder;
  margin: 0 4rem 1rem 4rem;
}

.order-wrapper table {
  width: 100%;
  color: #000;
}

.order-wrapper table .itemname {
  width: 30%;
}

.order-wrapper table td {
  /* text-align: center; */
}

.order-wrapper table tr {
  /* border-bottom: 30px solid transparent; */
}

.order-wrapper table span {
  color: #000;
  font-weight: bold;
  width: 100%;
  display: block;
  border: 1px solid #333;
  text-align: center;
}

.order-wrapper table .inprogress {
  background-color: #f3e760;
  color: #000;
  /* padding: 1rem; */
  height: 100%;
  /* width: 100%; */
  border-radius: 10px;
}

.order-wrapper table .done {
  background-color: #50b811;
  color: #000;
  /* padding: 0.8rem; */
  height: 100%;
  border-radius: 5px;
}

.order-wrapper table .delay {
  border: 1px solid #333;
  background-color: #444;
  color: #000;
  /* padding: 0.8rem; */
  height: 100%;
  border-radius: 5px;
}

.order-wrapper .order-btns-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.order-wrapper .order-btns-wrapper button {
  width: 25%;
  margin: 0 1rem;
  padding: 1rem;
  border: none;
  outline: none;
  font-weight: bolder;
  border-radius: 5px;
}

.order-wrapper .order-btns-wrapper .donebtn {
  background-color: #444;
  color: #fff;
  display: block;
}

.order-table-wrapper .donebtn {
  background-color: #444;
  color: #fff;
  /* display: block; */
  border: none;
  padding: 1rem;
  border-radius: 5px;
}
