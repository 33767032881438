.items-wrapper {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: rgba(252, 148, 2, 0.4);
  z-index: 10;
  cursor: pointer;
}

.items-container {
  position: absolute;
  height: 600px;
  width: 490px;
  background-color: #fff;
  border-radius: 20px;
  position: absolute;
  right: 17%;
  top: 10%;
  z-index: 20;
  opacity: 1 !important;
  display: grid;
  /* flex-wrap: wrap; */
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: min-content;
  /* grid-gap: 1rem; */
  overflow-y: scroll;
  overflow-x: hidden;
}

.item-wrapper {
  height: 150px;
  /* width: 25% !important; */
  border: 1px solid #ffbc5d;
  border-radius: 10px;
  margin: 0.7rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  overflow-y: hidden;
  cursor: pointer;
}

.item-wrapper .image-wrapper {
  height: 50px;
  width: 50px;
}

.item-wrapper .image-wrapper img {
  /* height: 50px;
  max-height: 50px;
  width: 50px;
  max-width: 50px; */

  border-radius: 50%;
  width: 50px;
  height: 50px;
  max-height: 50px;
  max-width: 50px;
  margin-right: 0.5rem;
}

.item-wrapper .itemname {
  width: 90%;
  text-align: center;
}

@media screen and (max-width: 900px) {
  .items-container {
    right: 21%;
  }
}
