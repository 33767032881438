.orders-wrapper {
  height: 100%;
  width: 100%;
  /* padding: 2rem; */
  position: relative;
  display: flex;
}

.scroll-wrapper {
  height: auto;
  margin: 1rem;
  width: 100px;
  /* background-color: red; */
  flex: 0.5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.orders-container {
  height: auto;
  width: 100%;
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  padding: 1rem;
  flex: 10;
}

@media screen and (max-width: 1150px) {
  .orders-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

.order-wrapper {
  background-color: antiquewhite;
  height: 100%;
  border-radius: 10px;
  padding: 1rem;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: space-between; */
}

.order-wrapper .order-wrapper-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #444;
}

.order-wrapper .order-wrapper-title p {
  font-weight: bolder;
}

.order-wrapper table {
  width: 100%;
  color: #000;
}

.order-wrapper table .itemname {
  width: 30%;
}

.order-wrapper table td {
  overflow-y: hidden;
  overflow-x: hidden;
}

.order-wrapper table tr {
  overflow-y: hidden;
  overflow-x: hidden;
}

.order-wrapper table span {
  color: #000;
  font-weight: bold;
  width: 100%;
  display: block;
  border: 1px solid #333;
}

.order-wrapper table .inprogress {
  background-color: #f3e760;
  color: #000;
  padding: 0.7rem;
  height: 100%;
  border-radius: 5px;
}

.order-wrapper table .done {
  background-color: #50b811;
  color: #000;
  padding: 0.7rem;
  height: 100%;
  border-radius: 5px;
}

.order-wrapper table .delay {
  border: 1px solid #333;
  background-color: #444;
  color: #fff;
  padding: 0.7rem;
  height: 100%;
  border-radius: 5px;
}

.order-wrapper .order-btns-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.order-wrapper .order-btns-wrapper button {
  width: 25%;
  margin: 0 1rem;
  padding: 1rem;
  border: none;
  outline: none;
  font-weight: bolder;
  border-radius: 5px;
}

.order-wrapper .order-btns-wrapper .payprintbtn {
  background-color: #444;
  color: #fff;
  display: block;
}

.order-wrapper .order-btns-wrapper .printbtn {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.94) 0%,
    rgba(255, 255, 255, 0.9) 100%
  );
  color: #444;
  border: 1px solid #444;
  display: block;
}
