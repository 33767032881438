.login-wrapper {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-box {
  height: auto;
  width: 500px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.8272) 0%,
    rgba(255, 255, 255, 0.5544) 100%
  );
  border-radius: 20px;
}

.logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
}

.login-form-wrapper {
  margin-top: 3rem;
  width: 95%;
  margin: 3rem 1rem;
}

.login-form-wrapper .form-group {
  margin-top: 3rem;
}

.login-form-wrapper label {
  /* color: red; */
  color: red;
  font-weight: 600;
}

.forget-title {
  /* color: red; */
  color: red;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  width: 60%;
  margin: auto;
  margin-top: 2rem;
}

.login-form-wrapper input {
  background-color: #fff;
  color: red;
}

.login-form-wrapper input:focus {
  background-color: #fff;
  color: red;
}

.login-form-wrapper a {
  display: block;
  text-decoration: none;
  margin-top: 1rem;
  color: red;
  font-weight: 600;
}

.reset-btn {
  box-sizing: border-box;
  margin-top: 3rem;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 45px;
  background: #91391e;
  border: 1px solid #91391e;
  border-radius: 5px;
}
