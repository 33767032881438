.add-area-wrapper {
  /* width: 95%; */
  margin: 1rem 2rem;
  height: auto;
  border-radius: 10px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.8272) 0%,
    rgba(255, 255, 255, 0.5544) 100%
  );
  padding: 2rem;
}

.add-area-wrapper .form-group {
  margin-bottom: 1.5rem;
}

.add-area-wrapper label {
  color: #444;
  font-weight: 600;
}

.add-area-wrapper ::placeholder {
  color: #444 !important;
}

.add-area-wrapper input {
  background-color: #fff;
  color: #444;
}

.add-area-wrapper textarea {
  background-color: #fff;
  color: #444;
}

.add-area-wrapper input:focus {
  background-color: #fff;
  color: #444;
}

.add-area-wrapper textarea:focus {
  background-color: #fff;
  color: #444;
}

.areas-table-wrapper {
  background-color: #ffedc9;
  padding: 0.5rem;
  margin: 2rem;
  border-radius: 10px !important;
  /* height: auto; */
  min-height: 100%;
}

.mui-11mde6h-MuiPaper-root {
  box-shadow: none !important;
}

.tss-1cdcmys-MUIDataTable-responsiveBase {
  background-color: #ffedc9;
  overflow: unset !important;
  box-shadow: none !important;
}

.actions-icon-wrapper {
  position: relative;
}

.actions-wrapper {
  position: absolute;
  top: 100%;
  left: -50%;
  width: 200px;
  height: auto;
  background-color: #d14418;
  border-radius: 10px;
  color: #fff;
  display: none;
  z-index: 1000;
}

.show {
  display: block !important;
}

.actions-wrapper ul {
  list-style: none;
  padding: 0;
  margin: none;
}

.actions-wrapper ul li {
  margin: 1rem;
}

.save-area {
  box-sizing: border-box;
  margin-top: 3rem;
  /* Auto layout */
  color: #fff;
  /* padding: 17px 8px; */
  width: 50%;
  height: 45px;
  background: #d14418;
  display: block;
  margin: auto;
  border: 1px solid #d14418;
  border-radius: 5px;
}
