.button-wrapper {
  margin: 1rem 0;
}

.add-btn {
  display: block;
  margin-left: auto;
  right: 0px;
  background-color: #444;
  color: #fff;
  border: none;
  padding: 1rem;
  border-radius: 5px;
}

.add-item {
  display: none;
  /* height: 0; */
  transform: scale(0);
  transition: transform 0.3s ease-in;

  width: auto;
  margin: unset;
  padding: 0.5rem;
}

@keyframes grow {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: none;
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(100%);
  }
}

.add-item.expand {
  /* display: block; */
  transform: scale(100%);
  opacity: 1;
  background-color: #444;
}

.add-item.expand.good {
  animation: grow 0.3s ease-in;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tabs-wrapper {
  width: 100%;
  height: fit-content;
  border-radius: 10px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  padding-bottom: 0;
}

.tabs-wrapper .tab {
  width: auto;
  margin: 0 1rem;
  background-color: #fff;
  color: #444;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 1rem;
  height: 50px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;
  /* box-shadow: 3px 3px 3px 10px rgba(0, 0, 0, 0.5); */
}

.tabs-wrapper .tab.active {
  background-color: #ffedc9;
}

.add-item-wrapper form {
  padding: 1rem;
}

.add-item-wrapper {
  width: 100%;
  height: 500px;
  border-radius: 10px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.8272) 0%,
    rgba(255, 255, 255, 0.5544) 100%
  );
  /* padding: 1rem; */
  /* margin: auto; */
  transition: all 0.3s;
  position: relative;
}

.add-item-wrapper.minimize {
  height: 30px;
}

.add-item-wrapper.minimize .form-wrapper {
  display: none;
}

.add-item-wrapper .header {
  height: auto;
  /* margin-top: 1rem; */
  /* margin-bottom: 1rem; */
  /* background-color: red; */
  height: 20px;
  width: 100%;
  position: relative;
}

.add-item-wrapper .header .MuiSvgIcon-root {
  position: absolute;
  top: -5px !important;
  right: 1% !important;
  cursor: pointer;
  display: block;
  margin-left: auto;
  right: 0px;
  font-size: 1.5rem;
}

.add-item-wrapper .header .expand {
  position: absolute;
  top: 4px !important;
  right: 1% !important;
  cursor: pointer;
  display: block;
  margin-left: auto;
  right: 0px;
  font-size: 1.5rem;
}

.add-item-wrapper .form-group {
  margin-bottom: 1.5rem;
}

.add-item-wrapper label {
  color: #90391e;
  font-weight: 600;
}

.add-item-wrapper ::placeholder {
  color: #90391e !important;
}

.add-item-wrapper input {
  /* background-color: #fff; */
  color: #90391e;
}

.add-item-wrapper textarea {
  background-color: #fff;
  color: #90391e;
}

.add-item-wrapper input:focus {
  background-color: #fff;
  color: #90391e;
}

.add-item-wrapper textarea:focus {
  background-color: #fff;
  color: #90391e;
}
